
import { Component, Vue, Watch } from "vue-property-decorator";


import * as StatServices from '@/services/StatServices'
@Component({
  components: {
    SocialInteracionsPerPartner: () => import('@/components/charts/SocialInteractionsPerPartner.vue'),
    TotalSocialInteractionsChart: () => import('@/components/charts/TotalSocialInteractionsChart.vue')
  }
})
export default class Social extends Vue {
  interactions: Record<string, any> = {}

  loading = true;

  mounted() {
    this.fetchInteractions();
  }

  @Watch('interactionsFilter', { deep: true })
  async fetchInteractions() {
    this.loading = true;
    this.interactions = await StatServices.ListInteractions(
      this.interactionsFilter.from,
      this.interactionsFilter.to
    );
    this.loading = false;
  }

  get twitterClickCount() {
    return Object.values(this.interactions).flat().filter(e => e.type === 9).length;
  }

  get facebookClickCount() {
    return Object.values(this.interactions).flat().filter(e => e.type === 8).length;
  }

  get totalSocialClickCount() {
    return Object.values(this.interactions).flat().filter(e => e.type === 7).length
  }

  interactionsFilter = {
    from: new Date(2022, 12, 12),
    to: new Date(2023, 1, 1)
  }

  dateRangeSelector = {
    fromToggle: false,
    from: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7).toISOString().substring(0, 10),
    toToggle: false,
    to: new Date().toISOString().substring(0, 10),
  }

  @Watch('dateRangeSelector.from', { immediate: true })
  @Watch('dateRangeSelector.to')
  onDateSelect() {
    this.interactionsFilter.from = new Date(this.dateRangeSelector.from);
    this.interactionsFilter.to = new Date(this.dateRangeSelector.to);
  }
}
